import {
  ConnectionRequestDto,
  ContactDetailsDto,
  CustomerConfigurationDto,
  FlowType,
  FormModule,
  FormRequestType,
  LocationDto,
  StatusType,
} from 'src/api/dso-portal/generated/models';

const flowTypeMapping = new Map<FormModule, FlowType[]>([
  [
    'ELECTRICITY',
    [
      'GRID_CONNECTION',
      'PV',
      'BALCONY_PV',
      'CHARGING_DEVICE',
      'HEAT_PUMP',
      'STORAGE',
      'MAIN_POWER_SUPPLY',
      'CUSTOMER_FACILITY',
      'OTHER',
    ],
  ],
  ['GAS', ['GRID_CONNECTION']],
  ['WATER', ['GRID_CONNECTION', 'SEWAGE', 'CONSTRUCTION_WATER']],
  ['HEAT', ['GRID_CONNECTION']],
]);

export interface RequestTableData {
  id?: string;
  module: FormModule;
  requestType: FormRequestType;
  requestId: string;
  flowType: FlowType;
  location: LocationDto;
  requesterContact: ContactDetailsDto;
  createdAt: string;
  status: StatusType;
}

export class RequestsMapper {
  static mapModules(configuration: CustomerConfigurationDto): FormModule[] {
    const registrationModules = configuration.moduleSettings.map(
      moduleSettings => moduleSettings.module
    );
    const commissioningModules = configuration.commissioningModules;
    return [...new Set(registrationModules.concat(commissioningModules))];
  }

  static mapFlowTypes(selectedModules: FormModule[]): FlowType[] {
    let availableFlowTypes: FlowType[] = [];
    selectedModules.forEach(module => {
      availableFlowTypes = availableFlowTypes.concat(
        flowTypeMapping.get(module)!
      );
    });
    return [...new Set(availableFlowTypes)];
  }

  static mapRequestList(
    requestList: ConnectionRequestDto[]
  ): RequestTableData[] {
    return requestList.map(request => ({
      id: request.id,
      module: request.module,
      requestId: request.requestId,
      requestType: request.requestType,
      flowType: request.flowType,
      location: request.location,
      requesterContact: request.requesterContact,
      createdAt: request.createdAt,
      status: request.currentStatus.status,
    }));
  }
}
